<template>
  <v-row>
    <v-col sm="5">
      <search-trainer-input
        v-model="form.trainer"
        label="Exercise trainer"
        :error-messages="form.$getError('trainer')"
        clearable
        outlined
        flat
      />
    </v-col>

    <v-col sm="7">
      <v-file-input
        v-model="form.excel_file"
        truncate-length="30"
        label="Exercises File"
        accept=".xls,.xlsx"
        :append-icon="icons.file"
        :error-messages="form.$getError('excel_file')"
        prepend-icon=""
        show-size
        outlined
        counter
        flat
      />
    </v-col>

    <v-col cols="12" class="d-flex align-center">
      <v-spacer></v-spacer>
      <v-btn
        class="px-12"
        color="primary"
        @click="saveTrainerExercises"
        :loading="form.$busy"
      >
        Import Exercises
      </v-btn>
    </v-col>

    <v-snackbar v-model="success" vertical absolute bottom right>
      Importing trainer exercises in the background.
      <template v-slot:action>
        <v-btn color="indigo" @click="success = false" text>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import SearchTrainerInput from '@/components/elements/trainers/TrainerSearchInput'
import Form from '@/utils/form'

import { mdiPaperclip } from '@mdi/js'

export default {
  name: 'ExerciseImportForm',

  components: {
    SearchTrainerInput,
  },

  data() {
    return {
      form: new Form({
        trainer: null,
        excel_file: null,
      }),

      icons: {
        file: mdiPaperclip,
      },

      success: false,
    }
  },

  methods: {
    async saveTrainerExercises() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        let formData = new FormData()

        formData.append('trainer', this.form.trainer)
        formData.append('excel_file', this.form.excel_file)

        await this.$api.post('exercises/import', formData)

        this.form.$timeout(() => {
          this.success = true
          this.form.$busy = false
          this.form.$reset()
        })
      } catch ({ response }) {
        this.form.$timeout(() => {
          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }
          this.form.$busy = false
        })
      }
    },
  },
}
</script>
