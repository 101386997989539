<template>
  <div class="px-3">
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="7" xl="5">
        <div class="d-flex justify-space-between align-center">
          <h2 class="mr-auto">Import Exercise Updates</h2>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="8" lg="7" xl="5">
        <exercise-update-form />
      </v-col>
    </v-row>

    <v-row v-if="hasSwapImportErrors" class="mt-5">
      <v-col cols="12" sm="12" md="8" lg="7" xl="5">
        <v-alert type="warning" prominent text outlined>
          The list below is/are the swap exercises that hasn't been added to
          it's respective exercises. You might want to check the exercise seeder
          file to confirm the correct swap exercises or check if the exercises
          is/are available on the active lists. The exercise with broken
          exercises swaps are moved to draft exercises.
        </v-alert>

        <v-card outlined>
          <v-card-text>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Officiis
            laudantium vero hic dignissimos magni? Eligendi esse harum libero
            excepturi numquam, illo hic eos accusantium quaerat maxime ab
            necessitatibus. Provident, vero.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ExerciseUpdateForm from '../components/ExerciseUpdateForm'

export default {
  name: 'ImportExercisesUpdates',

  components: {
    ExerciseUpdateForm,
  },

  data() {
    return {
      swapImportErrors: [],
    }
  },

  computed: {
    hasSwapImportErrors() {
      return !!this.swapImportErrors.length
    },
  },
}
</script>
